import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { useStaticQuery, graphql } from "gatsby"

//fontawesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser, faUserCog } from '@fortawesome/free-solid-svg-icons'

//components
// import Button from '../../../shared/components/button'
import { Button } from '../Button'


//style
import styles from './styles/navbar.module.scss'
import logo from '../../assets/images/logo2_m.png' 

export const Navbar = (props) => {

    const { site } = useStaticQuery(query)
    const {
        appUrl
    } = site.siteMetadata

    const [dropdown, setDropdown] = useState(false)

    const triggerNavbarToggle = (e) => {
        e.target.classList.toggle('is-active')
        let tager2 = document.getElementById(e.target.dataset.target)
        if (tager2) tager2.classList.toggle('is-active')
    }

    const triggerDropdown = () => {
        setDropdown(!dropdown)
    }

    return (
        <nav className={`navbar ${styles.core}`} role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <Link to={`/`} className={`navbar-item ${styles.navbar__item} ${styles.navbar__logo}`}>
                        <img src={logo} width="112" height="14" alt="Calendab" />
                    </Link>

                    <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarCC" onClick={(e) => triggerNavbarToggle(e)}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarCC" className="navbar-menu">
                    <div className="navbar-start">
                        {/* <div class={`navbar-item has-dropdown ${(dropdown) ? 'is-active' : ''} is-hoverable`} onClick={() => triggerDropdown()}>
                            <a className="navbar-link">
                            More
                            </a>

                            <div className="navbar-dropdown is-right">
                                <a className={`navbar-item ${styles.navbar__item}`}>
                                    About
                                </a>
                                <a className={`navbar-item ${styles.navbar__item}`}>
                                    Jobs
                                </a>
                                <a className={`navbar-item ${styles.navbar__item}`}>
                                    Contact
                                </a>
                                <hr className="navbar-divider" />
                                <a className={`navbar-item ${styles.navbar__item}`}>
                                    Report an issue
                                </a>
                            </div>
                        </div> */}
                    </div>

                    <div className="navbar-end">
                        <Link to={`/precios`} className={`navbar-item ${styles.navbar__item}`}>Precios</Link>
                        <Link to={`/contacto`} className={`navbar-item ${styles.navbar__item}`}>Contacto</Link>
                        <hr className="navbar-divider" />
                        <div className={`navbar-item ${styles.navbar__item}`}>
                            <Link to={`${appUrl}/login`} className={`navbar-item ${styles.navbar__item}`}>Iniciar sesión</Link>
                            <Link to={`${appUrl}/signup`} className={`navbar-item ${styles.navbar__item}`}>
                                <Button text={"Empieza gratis"} fullwidth />
                            </Link>
                            {/* <div className="buttons">
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

Navbar.propTypes = {

}

const query = graphql`
    query Navbar {
        site {
            siteMetadata {
                appUrl: appUrl
            }
        }
    }
`