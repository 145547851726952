import React from 'react'
// { useState, useEffect }
import PropTypes from 'prop-types'

// styles
// import './styles/_index.scss'
import style from './window.module.scss'

export const Window = (props) => {

    return (
        <div className={style.core}>
            <div className={style.header}>
                <div className={style.actions}>
                    <span className={`${style.action} ${style.action__close}`}></span>
                    <span className={`${style.action} ${style.action__min}`}></span>
                    <span className={`${style.action} ${style.action__full}`}></span>
                </div>
                <div className={style.title}>
                    <div className={style.browser}>
                        <div className={style.browser__space}></div>
                        <div className={style.browser__input}>
                            <div className={style.browser__url}>
                                <span className={style.browser__lock}></span>
                                {props.title}
                                <i className={style.browser__refresh}>
                                    <svg viewBox="0 0 24 24" width="12" height="12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" shapeRendering="geometricPrecision" style={{color:"currentColor"}}><path d="M23 4v6h-6"></path><path d="M20.49 15a9 9 0 11-2.12-9.36L23 10"></path></svg>
                                </i>
                            </div>
                        </div>
                        <div className={style.browser__space}></div>
                    </div>
                </div>
            </div>
            <div className={style.body}>
                {props.children}
            </div>
        </div>
    )
}

Window.propTypes = {
    title: PropTypes.string
}