import React, { useState } from 'react'
// { useState, useEffect }
import PropTypes from 'prop-types'

// styles
// import './styles/_index.scss'
import styles from './tooltip.module.scss'

export const Tooltip = ({ children, text, ...rest }) => {

    const [show, setShow] = useState(false);

    return (
        <span className={styles.container}>
            <span className={`${styles.box} ${show ? styles.box_visible : ''}`}>
                {text}
                {/* <span className={styles.arrow} /> */}
            </span>
            <span
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                {...rest}
            >
                {children}
            </span>
        </span>
    )
}

Tooltip.propTypes = {
}