import React from 'react'
// { useState, useEffect }
import PropTypes from 'prop-types'

// styles
// import './styles/_index.scss'
import style from './styles/button.module.scss'

export const Button = (props) => {

    return (
        <button 
            type={props.type} 
            className={`button 
            ${(props.secondary) ? style.btn__secondary : style.btn__primary}
            ${(props.isLoading) ? ' is-loading' : ''}
            ${(props.fullwidth) ? style.btn__fullw : ''}
            ${(props.fullwidthMobile) ? style.btn__fullwMobile : ''}
            ${(props.large) ? style.btn__large : ''}
            ${(props.black) ? style.btn__black : ''}
            ${(props.tall) ? style.btn__tall : ''}
            `}
            // className={`button btn`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.text}
        </button>
    )
    // return (
    //     <button 
    //         type={props.type} 
    //         className={`button btn${(!props.className) ? '' : ' ' +props.className}
    //         ${(props.isLoading) ? ' is-loading' : ''}`}
    //         // className={`button btn`}
    //         onClick={props.onClick}
    //         disabled={props.disabled}
    //     >
    //         {props.text}
    //     </button>
    // )
}

Button.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    secondary: PropTypes.bool,
    fullwidth: PropTypes.bool,
    large: PropTypes.bool,
    black: PropTypes.bool,
    tall: PropTypes.bool,
}