import React from "react"

import styles from './styles/header.module.scss'

import { Navbar } from '../index'

export const Header = (props) => {

    return (
        <header className="header">
            <div className="header__navbar">
                <Navbar />
            </div>
        </header>
    )

}