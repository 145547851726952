import React from "react"

import styles from './styles/default.module.scss'

import { Header, Footer } from '../../components'

export const DefaultLayout = ({ children }) => {

    // let tawkInit = () => {
    //     var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    //     (function(){
    //         var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    //         s1.async=true;
    //         s1.src='https://embed.tawk.to/606c6d08067c2605c0bfbc42/1f2jojr1b';
    //         s1.charset='UTF-8';
    //         s1.setAttribute('crossorigin','*');
    //         s0.parentNode.insertBefore(s1,s0);
    //     })();
    // }

    return (
        <div className={styles.core}>
            <Header />
            {children}
            <Footer />
            {/* <script src="https://embed.small.chat/T01T982B6UDC01TCAKT70S.js" async></script> */}
            {/* {tawkInit()} */}
            <script src="https://embed.tawk.to/606c6d08067c2605c0bfbc42/1f2jojr1b" async></script>
        </div>
    )
}
// export default Layout;