import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import styles from './footer.module.scss'

import { Container } from '../Container'

export const Footer = (props) => {

    const { site } = useStaticQuery(query)
    const {
        appUrl
    } = site.siteMetadata

    return (
        <header className={styles.core}>
            <Container className={styles.container} >
                <div className={styles.body}>
                    <div className="columns is-tablet is-multiline">
                        <div className="column">
                            <div className={styles.col}>
                                <div className={styles.title}>Compañia</div>
                                <ul className={styles.list}>
                                    <li className={styles.item}>
                                        <Link to={`/`} className={``}>Inicio</Link>
                                    </li>
                                    <li className={styles.item}>
                                        <Link to={`/precios`} className={``}>Precios</Link>
                                    </li>
                                    <li className={styles.item}>
                                        <Link to={`/contacto`} className={``}>Contacto</Link>
                                    </li>
                                    <li className={styles.item}>
                                        <Link to={`${appUrl}/login`} className={``}>Iniciar sesión</Link>
                                    </li>
                                    <li className={styles.item}>
                                        <Link to={`${appUrl}/signup`} className={``}>Crear cuenta</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="column">
                            <div className={styles.col}>
                                <div className={styles.title}>Legal</div>
                                <ul className={styles.list}>
                                    <li className={styles.item}>
                                        <Link to={`/legal`} className={``}>Aviso Legal</Link>
                                    </li>
                                    <li className={styles.item}>
                                        <Link to={`/privacidad`} className={``}>Política de Privacidad</Link>
                                    </li>
                                    <li className={styles.item}>
                                        <Link to={`/cookies`} className={``}>Política de Cookies</Link>
                                    </li>
                                    <li className={styles.item}>
                                        <Link to={`/condiciones`} className={``}>Condiciones</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={styles.love}>
                        /w  
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="20" height="20" className={styles.svg}><path fill="#6d54d4"  d="M414.9 24C361.8 24 312 65.7 288 89.3 264 65.7 214.2 24 161.1 24 70.3 24 16 76.9 16 165.5c0 72.6 66.8 133.3 69.2 135.4l187 180.8c8.8 8.5 22.8 8.5 31.6 0l186.7-180.2c2.7-2.7 69.5-63.5 69.5-136C560 76.9 505.7 24 414.9 24z"></path></svg> 
                        from Barcelona
                    </div>
                </div>
            </Container>
        </header>
    )

}

const query = graphql`
    query footer {
        site {
            siteMetadata {
                appUrl: appUrl
            }
        }
    }
`