import React from 'react'
// { useState, useEffect }
import PropTypes from 'prop-types'
// import { Link } from 'gatsby'

// import { Button } from '../Button'

// styles
// import './styles/_index.scss'
import styles from './item.module.scss'

export const Item = (props) => {

    return (
        <div className={styles.core}>
            <div className={styles.header}>
                {(props.icon) && (
                    <img src={props.icon} className={styles.icon} width="65px" height="65px" alt={props.alt} />
                )}
            </div>
            <div className={styles.body}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.desc}>{props.desc}</div>
            </div>
        </div>
    )
}

Item.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    icon: PropTypes.string,
    alt: PropTypes.string
}