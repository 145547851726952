import React from 'react'
// import PropTypes from 'prop-types'

// styles
import styles from './line.module.scss'

export const Line = (props) => {

    return (
        <span className={styles.core}></span>
    )
}

Line.propTypes = {}