import React from 'react'
import { Link, useStaticQuery, graphql} from 'gatsby'
import PropTypes from 'prop-types'

import { Button } from '../Button'
import { Container } from '../Container'

// styles
import styles from './call.module.scss'

export const Call = (props) => {

    const { site } = useStaticQuery(query)
    const {
        appUrl
    } = site.siteMetadata

    switch (props.version) {
        case 1:
            return (
                <div className={styles.core}>
                    <Container>
                        <div className="columns is-desktop">
                            <div className="column">
                                <div className={`${styles.content} ${styles.content__start}`}>
                                    <h3 className={styles.title}>¡Da el salto... digitalízate!</h3>
                                </div>
                            </div>
                            <div className="column">
                                <div className={`${styles.content} ${styles.content__end}`}>
                                    {/* <div className={styles.action}> */}
                                        <Link to={`${appUrl}/signup`} className={styles.action}>
                                            <Button text={"Empieza gratis"} fullwidth large black tall />
                                        </Link>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#6d54d4" fill-opacity="1" d="M0,288L20,245.3C40,203,80,117,120,85.3C160,53,200,75,240,74.7C280,75,320,53,360,80C400,107,440,181,480,197.3C520,213,560,171,600,176C640,181,680,235,720,234.7C760,235,800,181,840,149.3C880,117,920,107,960,122.7C1000,139,1040,181,1080,186.7C1120,192,1160,160,1200,149.3C1240,139,1280,149,1320,176C1360,203,1400,245,1420,266.7L1440,288L1440,320L1420,320C1400,320,1360,320,1320,320C1280,320,1240,320,1200,320C1160,320,1120,320,1080,320C1040,320,1000,320,960,320C920,320,880,320,840,320C800,320,760,320,720,320C680,320,640,320,600,320C560,320,520,320,480,320C440,320,400,320,360,320C320,320,280,320,240,320C200,320,160,320,120,320C80,320,40,320,20,320L0,320Z"></path>
                        </svg> */}
                    </Container>
                </div>
            )
            break;
        case 0:
        default:
            return (
                <div className={styles.core}>
                    <Container>
                        <div className="columns is-desktop">
                            <div className="column">
                                <div className={`${styles.content} ${styles.content__start}`}>
                                    <h3 className={styles.title}>Regístrate gratis <br/> ¡Sin tarjeta de crédito ni compromiso!</h3>
                                </div>
                            </div>
                            <div className="column">
                                <div className={`${styles.content} ${styles.content__end}`}>
                                    {/* <div className={styles.action}> */}
                                        <Link to={`${appUrl}/signup`} className={styles.action}>
                                            <Button text={"Empieza gratis"} fullwidth large black tall />
                                        </Link>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#6d54d4" fill-opacity="1" d="M0,288L20,245.3C40,203,80,117,120,85.3C160,53,200,75,240,74.7C280,75,320,53,360,80C400,107,440,181,480,197.3C520,213,560,171,600,176C640,181,680,235,720,234.7C760,235,800,181,840,149.3C880,117,920,107,960,122.7C1000,139,1040,181,1080,186.7C1120,192,1160,160,1200,149.3C1240,139,1280,149,1320,176C1360,203,1400,245,1420,266.7L1440,288L1440,320L1420,320C1400,320,1360,320,1320,320C1280,320,1240,320,1200,320C1160,320,1120,320,1080,320C1040,320,1000,320,960,320C920,320,880,320,840,320C800,320,760,320,720,320C680,320,640,320,600,320C560,320,520,320,480,320C440,320,400,320,360,320C320,320,280,320,240,320C200,320,160,320,120,320C80,320,40,320,20,320L0,320Z"></path>
                        </svg> */}
                    </Container>
                </div>
            )
            break;
    }

}

Call.propTypes = {
    version: PropTypes.number
}

const query = graphql`
    query Call {
        site {
            siteMetadata {
                appUrl: appUrl
            }
        }
    }
`