import React from 'react'
// { useState, useEffect }
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { Button } from '../Button'

// styles
// import './styles/_index.scss'
import styles from './plan.module.scss'

export const Plan = (props) => {

    return (
        <div className={styles.core}>
            <div className={styles.header}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.desc}>{props.desc}</div>
            </div>
            <div className={styles.body}>
                <div className={styles.price}>
                    {(props.price && props.price.length > 0 && (
                        <>
                            <div className={styles.price_main}><span className={styles.price__number}>{props.price[0]}€</span> /mensual</div>
                            <div className={styles.price_secondary}><span className={styles.price__number}>{props.price[1]}€</span> /anual</div>
                        </>
                    ))}
                </div>
                <div className={styles.action}>
                    <Link to={props.url} className={styles.action}>
                        <Button text={"Empezar"} fullwidth />
                    </Link>
                </div>
                <div className={styles.features}>
                    {(props.features && props.features.length > 0 && (
                        <>
                            {props.features.map((item) => (
                                <>
                                    <ul>
                                        <li className={styles.feature}>{item}</li>
                                    </ul>
                                    {/* <div className={styles.feature}>
                                        {item}
                                    </div> */}
                                </>
                            ))}
                        </>
                    ))}
                </div>
                <div className={styles.more}>
                    <Link to={(props.url_more) ? props.url_more : '/precios'} className={styles.link}>Saber más...</Link>
                </div>
            </div>
        </div>
    )
}

Plan.propTypes = {
    title: PropTypes.string,
    price: PropTypes.array,
    desc: PropTypes.string,
    features: PropTypes.array,
    url: PropTypes.string,
    url_more: PropTypes.string
}