import React from "react"

import styles from './styles/container.module.scss'

export const Container = ({ className, children }) => {
    return (
        <div className={`container ${styles.core} ${className ? className : ''}`}>
            {children}
        </div>
    )
}
// export default Layout;