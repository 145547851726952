import * as React from "react"
import { Link } from 'gatsby'

import { Button } from '../../components'

import styles from './contactform.module.scss';

// markup
export const ContactForm = () => {

    // const { site } = useStaticQuery(query)
    // const {
    //     appUrl
    // } = site.siteMetadata

    return (
        <div className={styles.core}>
            <form action="https://formspree.io/f/mqkwzeoz" method="POST">
                <div className="field">
                    <label className={`label ${styles.label}`} for="name">Nombre</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Tu nombre" name="name" id="name" required />
                    </div>
                </div>

                <div className="field">
                    <label className={`label ${styles.label}`} for="phone">Teléfono</label>
                    <div className="control">
                        <input className="input" type="phone" placeholder="Tu teléfono" name="phone" id="phone" required />
                    </div>
                    {/* <p className="help is-success">This username is available</p> */}
                </div>

                <div className="field">
                    <label className={`label ${styles.label}`} for="email">Correo</label>
                    <div className="control">
                        <input className="input" type="email" placeholder="Tu correo" name="email" id="email" required />
                    </div>
                    {/* <p className="help is-danger">This email is invalid</p> */}
                </div>

                <div className="field">
                    <label className={`label ${styles.label}`} for="message">Mensaje</label>
                    <div className="control">
                        <textarea className="textarea" placeholder="Tu mensaje" name="message" id="message" required ></textarea>
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <label className={`checkbox ${styles.checkbox}`}>
                            <input type="checkbox" required />
                            Acepto el envío de información.
                        </label>
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        {/* <button className="btn button is-link">Enviar</button> */}
                        <Button text={"Enviar"} fullwidth large black />
                    </div>
                </div>
            </form>
        </div>
    )
}
    

// const query = graphql`
//     query Grow {
//         site {
//             siteMetadata {
//                 appUrl: appUrl
//             }
//         }
//     }
// `